/*------------------------------------------------
    blog style
------------------------------------------------*/
.single-blog-inner {
  margin-bottom: 50px;
  overflow: hidden;
  transition: 0.4s;
  .thumb {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 10px;
    .date {
      height: 94px;
      width: 84px;
      background: var(--main-color);
      position: absolute;
      left: 20px;
      top: 20px;
      border-radius: 5px;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      text-align: center;
      padding: 15px;
      padding-top: 22px;
    }
    img {
      transform: scale(1.05);
      transition: 0.9s;
    }
  }
  .blog-meta {
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
    li {
      display: inline-block;
      margin-right: 15px;
      color: #8a8a8a;
      font-size: 14px;
      i {
        margin-right: 4px;
        color: var(--main-color);
      }
      svg {
        margin-right: 4px;
        color: var(--main-color);
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .details {
    h2 {
      margin-bottom: 3px;
      &:hover a {
        color: var(--main-color);
      }
    }
    .title {
      margin-bottom: 17px;
    }
    p {
      margin-bottom: 16px;
    }
    .read-more-text {
      font-weight: 600;
    }
  }
  .portfolio-details {
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--main-color);
    border-radius: 0 30px 0 0;
    overflow: hidden;
    h5 {
      padding: 10px 30px;
      background: var(--heading-color);
      color: #fff;
    }
    p {
      margin-bottom: 4px;
      color: #fff;
      border-bottom: 1px dashed #fff;
      padding: 5px 30px 7px 30px;
      &:last-child {
        border-bottom: 0;
      }
    }
    strong {
      min-width: 95px;
      display: inline-block;
      color: #fff;
    }
  }
  &:hover {
    .thumb {
      img {
        transform: scale(1.1);
      }
    }
  }
}

.single-blog-list {
  border-radius: 6px;
  box-shadow: $box-shadow;
  margin-bottom: 30px;
  overflow: hidden;
  transition: 0.4s;
  .thumb {
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      transition: 0.7s;
    }
    .date {
      position: absolute;
      right: 0;
      top: 25px;
      background: #fff;
      padding: 9px 18px;
      border-radius: 5px 0 0 5px;
      border-left: 3px solid var(--main-color);
      color: var(--heading-color);
      font-weight: 600;
    }
    .date-2 {
      background: var(--main-color);
      padding: 9px 20px;
      border-radius: 7px;
      color: #fff;
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
  .details {
    padding: 25px 30px;
    position: relative;
    .cat {
      position: absolute;
      right: 0;
      top: -24px;
      background: var(--main-color);
      padding: 9px 18px;
      border-radius: 5px 0 0 5px;
      color: #fff;
    }
    .blog-meta {
      li {
        list-style: none;
        display: inline-block;
        margin-right: 12px;
        font-size: 12px;
        svg {
          color: var(--main-color);
          margin-right: 4px;
        }
      }
    }
    p {
      font-weight: 500;
      i,
      svg {
        margin-right: 10px;
      }
    }
    h5 {
      margin-bottom: 0;
    }
    .meta {
      margin-top: 20px;
      padding-top: 18px;
      border-top: 1px solid #ededed;
      p {
        margin-bottom: 0;
      }
    }
    &.pink {
      ul {
        li {
          svg {
            margin-bottom: 6px;
            color: var(--main-color3);
            margin-right: 4px;
          }
        }
      }
    }
  }
  &:hover {
    box-shadow: $box-shadow2;
    .thumb {
      img {
        transform: scale(1.1);
      }
    }
  }
  &.style-2 {
    .thumb {
      img {
        border-radius: 5px;
      }
    }
    .details {
      .blog-meta {
        display: flex;
        align-items: center;
        background: #f8f9fc;
        padding: 10px;
        border-radius: 6px 0 0 6px;
        margin-right: -30px;
        margin-left: 50px;
        margin-top: -64px;
        z-index: 2;
        position: relative;
        .date {
          height: 50px;
          width: 50px;
          border-radius: 5px;
          background: var(--main-color);
          color: #fff;
          text-align: center;
          padding: 8px;
          line-height: 1.4;
        }
      }
    }
  }
  &.style-3 {
    .thumb {
      img {
        border-radius: 5px;
      }
    }
    .details {
      .blog-meta {
        display: flex;
        align-items: center;
        background: #50af9b;
        padding: 10px;
        border-radius: 6px;
        margin-right: 48px;
        margin-top: -54px;
        z-index: 2;
        position: relative;
        li {
          color: #fff;
          svg {
            color: #fff;
          }
          span {
            border-radius: 50%;
            border: 1px solid #fff;
            padding: 3px;
            display: inline-block;
            height: 30px;
            width: 30px;
            margin-right: 3px;
            img {
              border-radius: 50%;
            }
          }
        }
        .date {
          height: 50px;
          width: 50px;
          border-radius: 5px;
          color: #fff;
          text-align: center;
          padding: 8px;
          line-height: 1.4;
        }
      }
      .btn-wrap {
        position: relative;
        .read-more-text {
          background: #fff;
          z-index: 2;
          position: relative;
          padding-left: 5px;

          svg {
            background: #fff;
          }
        }
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 15px;
          height: 1px;
          width: 100%;
          background: #ccc;
          opacity: 1;
          visibility: visible;
        }
        &:hover {
          .read-more-text {
            color: #50af9b;
            &::after {
              background: #50af9b;
            }
          }
          &::after {
            background: #50af9b;
          }
        }
      }
    }
  }
}

/************ page navigation ************/
.pagination {
  display: inline-flex;
  margin: 10px 0 0 0;
  .prev,
  .next {
    @include transition(all, 0.4s, ease);
  }
  .page-numbers {
    height: 40px;
    width: 40px;
    margin-right: 8px;
    border: 0;
    border-radius: 5px;
    background: #f8f9fc;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    @include transition(all, 0.4s, ease);
    display: block;
    color: var(--heading-color);
    font-size: 16px;
    font-weight: 700;
    &:active,
    &:focus,
    &:hover {
      background: var(--main-color);
      color: #fff;
    }
    &.current {
      background: var(--main-color);
      color: #fff;
    }
  }
}

/******** blog-details-page-content ***********/
.blog-details-page-content {
  .single-blog-inner {
    box-shadow: none !important;
    border-radius: 0;
    border-bottom: 0;
    padding-bottom: 0;
  }
  blockquote {
    background: #fff;
    padding: 30px 40px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;
    border: 1px solid rgba(66, 74, 97, 0.1);
    text-align: center;
    p {
      margin-bottom: 0 !important;
      text-align: left;
    }
    svg {
      height: 50px;
      width: 50px !important;
      line-height: 50px;
      padding: 15px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      color: #fff;
      background: var(--main-color);
      margin-bottom: 15px;
    }
    .name {
      position: relative;
      padding-left: 40px;
      margin-top: 15px;
      text-align: left;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 12px;
        width: 28px;
        height: 3px;
        background: var(--main-color);
      }
    }
  }
}

/*********** tag-and-share ************/
.tag-and-share {
  margin-top: 20px;
  border-bottom: 1px solid #e5e5e5;
  padding: 15px 0;
  .tags {
    a {
      height: 30px;
      line-height: 29px;
      padding: 0 12px;
      background: #f2f5f6;
      display: inline-block;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 600;
      color: #757575;
      transition: 0.4s;
      border: 1px solid rgba(66, 74, 97, 0.1);
      &:hover {
        background: var(--main-color);
        color: #fff;
        border-color: var(--main-color);
      }
    }
  }
  strong {
    color: var(--heading-color);
    margin-right: 6px;
    font-size: 20px;
  }
  .blog-share {
    ul {
      margin: 0;
      padding: 0;
      display: inline-block;
      li {
        display: inline-block;
        list-style: none;
        margin-right: 10px;
        color: var(--heading-color);
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

/****** prev-next-post ******/
.prev-next-post {
  padding: 20px;
  margin-top: 50px;
  border: 1px solid #ededed;
  border-radius: 5px;
  .btn {
    height: 50px;
    width: 50px;
    line-height: 50px;
    padding: 0;
    text-align: center;
    display: inline-block;
    svg {
      font-size: 12px;
      margin-top: 0;
      margin-left: 0;
      float: initial;
    }
  }
}

.blog-comment-form {
  padding-top: 30px;
  h3 {
    position: relative;
    margin-bottom: 32px;
  }
}

.comment-content a {
  word-wrap: break-word;
}

.bypostauthor {
  display: block;
}

.blog-comment {
  position: relative;
  margin-top: 40px;
}

.blog-comment .comment-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.blog-comment .comment-list ul.children {
  margin: 0;
  list-style: none;
  padding-left: 65px;
}

.blog-comment .comment-box {
  position: relative;
  margin-bottom: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid var(--aivons-light, #f2f4f8);
}

.blog-comment .comment {
  position: relative;
  min-height: 90px;
}

.blog-comment .comment-box .author-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-bottom: 20px;
  overflow: hidden;
}

.blog-comment .comment-box .author-thumb img {
  width: 90px;
  height: 90px;
  display: block;
  border-radius: 50%;
}

.blog-comment .comment-box .info {
  position: relative;
  margin-bottom: 25px;
  line-height: 24px;
}

.blog-comment .comment-box .info .name {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 30px;
  font-weight: 800;
  color: var(--aivons-black, #0f0d1d);
  text-transform: uppercase;
}

.blog-comment .comment-box .info .date {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: var(--main-color);
}

.blog-comment .comment-box .text {
  position: relative;
  display: block;
  color: var(--aivons-gray, #726f84);
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
}

.blog-comment .comment-box .reply-btn {
  position: relative;
  display: block;
}

.blog-comment .comment-box .theme-btn .btn-title {
  padding: 6px 30px 4px;
  line-height: 30px;
}

.blog-comment .comment-body .says {
  display: none;
}

.blog-comment .comment-body {
  position: relative;
  padding-left: 100px;
  margin-bottom: 30px;
}

.blog-comment .pingback .comment-body,
.blog-comment .trackback .comment-body {
  padding-left: 0;
}

.blog-comment .comment-body .avatar {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  height: 85px;
  width: 85px;
}

.blog-comment .fn a,
.blog-comment .fn {
  position: relative;
  display: block;
  font-size: 18px;
  color: var(--heading-color);
  text-decoration: none;
  line-height: 1;
}

.blog-comment .comment-body .comment-metadata {
  margin-bottom: 13px;
}

.blog-comment .comment-body .comment-metadata a {
  position: relative;
  display: inline-block;
  font-size: 12px;
  text-decoration: none;
}

.blog-comment .comment-body .comment-metadata a:hover {
  color: var(--aivons-black, #0f0d1d);
}

.blog-comment .comment-body .comment-metadata .edit-link a::before {
  content: ".";
  margin-left: 8px;
  margin-right: 10px;
}

.blog-comment .comment-content p {
  position: relative;
  display: block;
  color: var(--aivons-gray, #726f84);
  font-weight: 300;
  line-height: 1.5em;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 2em;
  font-family: var(--aivons-font, "Jost", sans-serif);
  letter-spacing: 0;
  font-weight: 500;
}

.blog-comment .comment-content table a {
  color: var(--main-color);
  text-decoration: none;
}

.blog-comment .comment-content {
  font-weight: 500;
}

.blog-comment .comment-content table a {
  color: var(--aivons-black, #0f0d1d);
}

.blog-comment .comment-content table a:hover {
  color: var(--main-color);
}

.blog-comment .comment-content p:last-of-type {
  margin-bottom: 0;
}

.blog-comment .reply a {
  position: relative;
  background-color: var(--main-color);
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  height: 30px;
  line-height: 30px;
  transition: all 500ms ease;
  margin-top: 40px;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  border: none;
  outline: none !important;
  padding: 0 18px;
  border-radius: 30px;
  text-transform: capitalize;
}

.blog-admin {
  background: #f8f9fc;
  padding: 30px;
  border-radius: 5px;
  p {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .blog-comment .reply a {
    margin-top: 0;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.blog-comment .reply a:hover {
  background-color: var(--aivons-black, #0f0d1d);
  color: #ffffff;
}

.blog-comment .blog-pagination a,
.blog-comment .blog-pagination span {
  margin-bottom: 45px;
}

.comment-respond .blog-details__content-title {
  margin-bottom: 50px;
}

.reply-form .logged-in-as {
  display: flex;
  color: var(--main-color);
  margin-left: -10px;
  margin-top: -40px;
  margin-bottom: 20px;
}

.reply-form .logged-in-as a {
  text-decoration: none;
  margin-left: 10px;
  margin-right: 10px;
}

.comment-form__title small {
  font-size: 20px;
}

.comment-form__title small a {
  text-decoration: none;
  color: var(--main-color);
  text-decoration: underline;
}

.comment-form__title small a:hover {
  color: var(--aivons-black, #0f0d1d);
  opacity: 0.5;
}

.comment-list .comment-respond {
  margin-bottom: 30px;
}

.reply-form .form-submit {
  margin-bottom: 0;
}

.comment-list .reply-form .form-submit {
  margin-bottom: 30px;
}
