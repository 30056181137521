.root-demo {
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
  :root {
    --main-color: #246bfd;
    --main-color-opacity: 237, 28, 36;
    --main-gradient: linear-gradient(to right, #2878eb, #f129c9);
    --heading-color: #111;
  }
  body {
    font-family: 'Inter', sans-serif;
    font-size: 17px;
    background-color: #f1f1f1;
  }
  @media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      max-width: 1170px;
    }
  }
  /***default-btn-area***/
  .btn {
    height: 50px;
    line-height: 50px;
    padding: 0 32px;
    overflow: hidden;
    position: relative;
    border: 0;
    transition: all 0.5s ease 0s;
    font-weight: 700;
    display: inline-block;
    transform: perspective(1px) translateZ(0);
    border-radius: 3px;
  }
  .btn:focus,
  .btn:active {
    outline: 0;
    box-shadow: none;
  }
  .btn:after {
    content: '';
    background: var(--main-color);
    position: absolute;
    transition: all 0.9s ease 0s;
    z-index: -1;
    height: 100%;
    left: -35%;
    top: 0;
    transform: skew(30deg);
    transform-origin: top left;
    width: 0;
  }
  .btn:hover {
    color: #fff;
  }
  .btn:hover:after {
    height: 100%;
    width: 135%;
  }

  .nav-right-part-mobile ul li a {
    text-decoration: none;
    padding: 0 10px;
    color: #fff;
  }
  .btn-base {
    color: #fff;
    border: 0;
    background: var(--main-color);
  }
  .btn-base:after {
    background: #fff;
  }
  .btn-base:hover::after {
    background: var(--main-color);
  }
  .btn-border {
    color: var(--main-color);
    border: 1px solid var(--main-color);
    background: transparent;
  }
  .btn-border:hover {
    color: #fff;
  }
  .btn-border-white {
    color: #fff;
    border: 1px solid #fff;
    background: transparent;
  }
  .btn-border-white:hover {
    background: #fff;
    opacity: 1;
    color: var(--main-color);
  }
  .btn-border-white:hover::after {
    background: #fff;
  }
  .btn-white {
    color: var(--main-color);
    border: 0;
    background: #fff;
    font-size: 14px;
    transition: all 0.3s ease 0s;
  }
  .btn-white:hover {
    color: var(--main-color) !important;
  }
  .btn-white:hover:after {
    background: #fff;
  }
  .btn-area {
    animation: 1.5s 0.8s fadeInLeft both;
  }
  .btn-area .btn {
    margin-right: 7px;
  }

  .top-nav-collapse ul.navbar-nav li a {
    color: #fff !important;
  }
  /*------------------------------------------------
      ## Back Top
  ------------------------------------------------*/
  #back-to-top {
    display: inline-block;
    background-color: var(--main-color);
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 0px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    opacity: 0;
    animation: backto-top-bounce 4s infinite ease-in-out;
    visibility: hidden;
    z-index: 1000;
  }
  #back-to-top::after {
    content: '';
    position: absolute;
    background: #fff;
    top: 50%;
    margin-top: -2.5px;
    height: 3px;
    width: 14px;
    line-height: 50px;
    transform: rotate(-43deg);
    left: 8.5px;
  }
  #back-to-top::before {
    content: '';
    position: absolute;
    background: #fff;
    top: 50%;
    margin-top: -2.5px;
    height: 2px;
    width: 12px;
    line-height: 50px;
    transform: rotate(50deg);
    right: 10.5px;
  }
  #back-to-top:hover {
    cursor: pointer;
    background-color: #4285f4;
  }
  #back-to-top:active {
    background-color: #4285f4;
  }
  #back-to-top.show {
    opacity: 1;
    visibility: visible;
  }
  /* .back-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 44px;
    height: 44px;
    color: #fff;
    background-color: var(--main-color);
    text-align: center;
    line-height: 44px;
    z-index: 99;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    display: none;
    animation: backto-top-bounce 4s infinite ease-in-out;
    border-radius: 4px;
  } */
  @keyframes backto-top-bounce {
    0% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-5px);
    }
  }

  .main-logo img {
    width: 130px;
  }
  /***navbar-area***/
  .navbar-area {
    background: transparent;
    padding: 0;
    top: 0;
    position: fixed;
    z-index: 98;
    width: 100% !important;
    transition: all 0.4s;
    background: transparent;
  }
  .navbar {
    background: transparent;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    transition: all 0.3s linear;
  }
  .top-nav-collapse {
    box-shadow: 0px 4px 6px 0px rgb(12 0 46 / 5%);
    top: 0;
    padding: 0;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background: var(--main-color);
  }
  .navbar-area .nav-container {
    transition: all 0.4s;
    padding: 10px 12px;
  }
  .top-nav-collapse .nav-container {
    background-color: transparent;
    padding: 10px 12px;
    transition: all 0.4s;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    margin-top: 0;
    display: inline-block;
    font-weight: 400;
    line-height: 50px;
    text-transform: capitalize;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
    font-size: 16px;
    text-decoration: none;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li + li {
    margin-left: 20px;
  }
  .nav-right-part-desktop {
    margin-left: 30px;
  }
  .nav-right-part ul {
    padding: 0;
    margin: 0;
    display: inline-flex;
  }
  .nav-right-part ul li {
    list-style: none;
    align-self: center;
  }
  .nav-right-part-mobile {
    display: none;
  }
  .nav-right-part-mobile ul li a {
    text-decoration: none;
    padding: 0 10px;
  }
  .nav-right-part-mobile ul li .cart {
    display: none;
  }
  .nav-right-part-mobile ul li .cart img {
    height: 20px;
    width: 20px;
    filter: invert(1);
  }
  /***banner-area***/
  .banner-area {
    background: var(--main-gradient);
    padding: 400px 0 200px 0;
    background-size: cover !important;
    position: relative;
    background-position: center !important;
    overflow: hidden;
    background: fixed;
  }
  .banner-animate-image {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: 10%;
    margin-left: 8%;
    background-repeat: no-repeat;
    background-size: 85%;
    top: 10%;
  }
  .banner-area img {
    max-width: 100%;
  }
  .banner-area:after {
    content: '';
    position: absolute;
    opacity: 0.9;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  .banner-area .sidebar-links {
    position: fixed;
    right: 0;
    top: 50%;
    display: inline-block;
    margin-right: -99px;
    transition: 0.4s;
    transform: translateY(-50%);
    margin-top: -70px;
    z-index: 9;
  }
  .banner-area .sidebar-links:hover {
    margin-right: 0;
  }
  .banner-area .sidebar-links .btn {
    border-radius: 0;
    margin-bottom: 5px;
    width: 100%;
    padding: 0 18px;
    display: block;
    margin-right: 0;
    border: 1px solid #eae5e5;
  }
  .banner-area .sidebar-links .btn i {
    margin-right: 15px;
    font-size: 22px;
    float: left;
    margin-top: 14px;
  }
  .banner-inner {
    position: relative;
    z-index: 9;
    margin-top: -100px;
  }
  .banner-inner h6 {
    background: #ffffff;
    box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05);
    display: inline-block;
    padding: 6px 20px;
    border-radius: 35px;
    font-size: 14px;
    margin-bottom: 25px;
    text-transform: uppercase;
    animation: 0.5s 0.4s fadeInUp both;
  }
  .banner-inner h1 {
    line-height: 75px;
    font-weight: 700;
    font-size: 56px;
    margin-bottom: 30px;
    animation: 1s 0.4s fadeInUp both;
  }
  .banner-inner h1 span {
    display: block;
  }
  .banner-inner p {
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 36px;
  }
  .banner-inner .element {
    animation: 1.2s 0.4s fadeInUp both;
  }
  .banner-inner .element span {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 45px;
    padding: 6px 20px;
    display: inline-block;
    margin: 5px;
  }
  .banner-inner .me-5 {
    animation: 1.5s 0.6s fadeInLeft both;
  }
  .banner-inner .banner-counter-wrap {
    animation: 1.5s 0.7s fadeInLeft both;
  }
  .banner-inner .single-counter-inner .details h2 {
    color: #fff;
  }

  /********* single-counter-inner *********/
  .counter-area {
    background-size: cover;
  }
  .counter-area-inner {
    padding: 50px 0 0 0;
    border-radius: 7px;
  }
  .single-counter-inner {
    margin-bottom: 50px;
  }
  .single-counter-inner h2 {
    font-size: 60px;
    font-weight: 700;
  }
  .single-counter-inner p {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
  }

  /***inner-item***/
  .inner-item {
    text-align: center;
    margin-bottom: 50px;
    position: relative;
  }
  .inner-item .al-batch {
    height: 50px;
    width: 50px;
    font-size: 14px;
    background: #ff8400;
    border-radius: 50%;
    position: absolute;
    right: -12px;
    top: -20px;
    line-height: 50px;
    color: #fff;
    font-weight: 600;
  }
  /*.inner-item .thumb img {
    transition: transform 5000ms cubic-bezier(.455, .030, .515, .955);
  }*/
  /*.inner-item:hover .thumb img {
    transform: translateY(calc(-100% + 430px));
  }*/
  .inner-item a {
    display: block;
    font-size: 22px;
    font-weight: 500;
    color: #1d1d1d;
    text-decoration: none;
    position: relative;
  }
  .inner-item a .thumb {
    overflow: hidden;
    display: block;
    position: relative;
    border-radius: 5px;
    margin-bottom: 15px;
    box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 20%);
    min-height: auto;
    transition: 0.6s;
  }
  .inner-item a .thumb:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    opacity: 0;
    transition: 0.4s;
  }
  .inner-item a img {
    /*position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: scale(1) translateY(1);
    transition: transform 12s;
    display: block;
    width: 100%;
    height: initial;*/
    max-width: 100%;
    padding: 18px;
  }
  .inner-item:hover a .thumb:after {
    visibility: visible;
    opacity: 1;
  }
  .header-item {
    display: block;
  }
  .header-item .thumb {
    position: relative;
    display: block;
  }
  .header-item .thumb:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    opacity: 0;
    transition: 0.4s;
    border-radius: 5px;
  }
  .header-item .thumb img {
    transition: 0.4s;
    border-radius: 5px;
  }
  .header-item:hover .thumb img {
    transform: scale(1.05);
  }
  .header-item:hover .thumb:after {
    visibility: visible;
    opacity: 1;
    transform: scale(1.05);
  }

  .inner-item.coming-soon a .thumb img {
    filter: blur(12px);
  }
  .cm-soon-title {
    position: absolute;
    left: 50%;
    top: 50%;
    color: #1d1d1d;
    transform: translate(-50%, -50%);
  }
  .inner-item:hover .cm-soon-title {
    color: #fff;
  }
  .inner-item .btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #fff;
    margin-top: -22px;
    visibility: hidden;
    opacity: 0;
    transition: 0.4s;
  }
  .inner-item:hover .btn {
    visibility: visible;
    opacity: 1;
  }

  /***section-title***/
  .section-title {
    margin-bottom: 60px;
  }
  .section-title h5 {
    font-weight: 600;
    color: var(--main-color);
    margin-bottom: 6px;
    font-size: 18px;
  }
  .section-title h2 {
    font-size: 48px;
    font-weight: 700;
    color: var(--heading-color);
  }
  .section-title p {
    font-size: 22px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.4);
  }
  /***default-padding***/
  .pd-top-100 {
    padding-top: 100px;
  }
  .pd-top-47 {
    padding-top: 47px;
  }
  .pd-top-70 {
    padding-top: 70px;
  }
  .pd-top-87 {
    padding-top: 87px;
  }
  .pd-top-110 {
    padding-top: 110px;
  }
  .pd-top-120 {
    padding-top: 120px;
  }
  .pd-top-135 {
    padding-top: 135px;
  }
  .pd-top-130 {
    padding-top: 130px;
  }
  .pd-top-140 {
    padding-top: 140px;
  }
  .pd-top-150 {
    padding-top: 150px;
  }

  .pd-bottom-100 {
    padding-bottom: 100px;
  }
  .pd-bottom-97 {
    padding-bottom: 97px;
  }
  .pd-bottom-65 {
    padding-bottom: 65px;
  }
  .pd-bottom-105 {
    padding-bottom: 105px;
  }
  .pd-bottom-110 {
    padding-bottom: 110px;
  }
  .pd-bottom-120 {
    padding-bottom: 120px;
  }
  .pd-bottom-130 {
    padding-bottom: 130px;
  }
  .pd-bottom-140 {
    padding-bottom: 140px;
  }
  .pd-bottom-150 {
    padding-bottom: 150px;
  }

  .typed::after {
    content: '|';
    display: inline;
    -webkit-animation: blink 0.7s infinite;
    -moz-animation: blink 0.7s infinite;
    animation: blink 0.7s infinite;
  }

  /*Removes cursor that comes with typed.js*/
  .typed-cursor {
    opacity: 0;
    display: none !important;
  }
  /*Custom cursor animation*/
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media all and (max-width: 1599px) {
    .banner-area {
      padding: 400px 0 200px 0;
    }
  }
  @media all and (max-width: 1199px) {
    .banner-area .bg-image {
      opacity: 0.2;
      z-index: 0;
    }
    .banner-inner p {
      margin: 0 20px !important;
    }
    .btn {
      height: 50px;
      line-height: 50px;
    }
    .pd-top-140 {
      padding-top: 100px;
    }
    .pd-bottom-97 {
      padding-bottom: 57px;
    }
    .pd-top-87 {
      padding-top: 47px;
    }
    .banner-area {
      padding: 400px 0 200px 0;
    }
    .banner-animate-image {
      display: none;
    }
  }
  @media all and (max-width: 991px) {
    .nav-right-part-mobile {
      display: block;
    }
    .banner-inner h1 {
      font-size: 70px;
    }
    .banner-inner {
      margin-top: 100px;
    }
  }
  @media all and (max-width: 575px) {
    .nav-right-part-desktop {
      margin-left: 10px;
    }
    .section-title h5 {
      font-size: 18px;
    }
    .counter-area {
      transform: translateY(0);
    }
    .single-counter-inner h2 {
      font-size: 30px;
    }
  }
  @media all and (max-width: 767px) {
    .nav-right-part ul li .btn {
      display: none;
    }
    .nav-right-part-mobile ul li .cart {
      display: block;
    }
    .nav-right-part-desktop {
      margin-left: 0px;
    }
    .banner-inner h1 {
      line-height: 46px;
      font-size: 33px;
      margin-bottom: 15px;
    }
    .banner-inner p {
      font-size: 18px;
      line-height: inherit;
      letter-spacing: 0;
    }
    .banner-inner p span {
      font-size: 20px;
    }
    .banner-area {
      padding: 180px 0px 100px;
    }
    .section-title h2 {
      font-size: 30px;
    }
    .btn {
      padding: 0 21px;
    }
    .footer-widget.pd-bottom-100 {
      padding-bottom: 70px;
    }
    .footer-widget h5 {
      font-size: 16px;
    }
    .main-logo img {
      width: 140px;
    }
    .banner-inner .element span {
      margin: 3px;
      font-size: 12px;
    }
  }
  @media all and (max-width: 320px) {
    .main-logo img {
      width: 110px;
    }
    .nav-right-part ul li {
      font-size: 15px;
    }
    .nav-right-part ul li a {
      padding: 0 7px;
    }
  }
  .featured-item {
    box-shadow: 0 0 40px rgb(82 85 90 / 10%);
    text-align: center;
    margin-bottom: 30px;
    padding: 40px 20px 35px 20px;
    background: #fff;
    border-radius: 5px;
    color: #1d1d1d;
    font-size: 22px;
  }
  .featured-item img {
    display: block;
    margin: 0 auto 20px;
    box-shadow: 0 0 40px rgb(82 85 90 / 20%);
    border-radius: 5px;
  }
  .footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding: 25px 0;
    position: relative;
    z-index: 2;
  }
  .footer-bottom p {
    margin-bottom: 0;
    color: #fff;
  }
  .footer-bottom p a {
    color: #ff8400;
    text-decoration: none;
    font-weight: 600;
  }
  .footer-area {
    background: #333;
    position: relative;
  }
  .footer-widget {
    position: relative;
    z-index: 9;
  }
  .footer-area:after {
    content: '';
    position: absolute;
    background: rgb(0, 0, 0, 0.7);
    opacity: 0.9;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  .footer-widget .logo img {
    width: 140px;
  }

  .single-pricing-wrap {
    border: 2px solid #e9e9e9;
    padding: 60px;
    margin-bottom: 30px;
    background: #fff;
  }
  .single-pricing-wrap .price {
    border-bottom: 1px solid #eaeaea;
    margin-top: -7px;
    padding-bottom: 10px;
    margin-bottom: 31px;
  }
  .single-pricing-wrap .price span {
    display: inline-block;
  }
  .single-pricing-wrap span.title {
    font-size: 24px;
    margin-bottom: 15px;
    display: inline-block;
  }
  .single-pricing-wrap .price h2 {
    display: inline-block;
    font-size: 45px;
  }
  .single-pricing-wrap .pricing-list {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 33px;
  }
  .single-pricing-wrap .pricing-list li {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .single-pricing-wrap .pricing-list li:last-child {
    margin-bottom: 0;
  }
  .single-pricing-wrap .pricing-list li.unable {
    color: #cdcdcd;
  }
  .single-pricing-wrap .pricing-list li i {
    margin-right: 20px;
  }
  .single-pricing-wrap .more-work-btn {
    width: 100%;
  }
  .single-pricing-wrap .primary-btn {
    background: var(--heading-color);
  }
  .video-play-btn {
    border-radius: 50%;
    background: #f7f7f7;
    width: 90px;
    height: 90px;
    display: inline-block;
    line-height: 100px;
    position: relative;
    z-index: 0;
    text-align: center;
    animation: ripple-white3 2.5s linear infinite;
    z-index: 9;
  }
  .video-play-btn:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background: rgba(247, 247, 247, 0.4);
    top: -10px;
    left: -10px;
  }
  .video-play-btn i {
    color: #585858;
    margin-left: 6px;
    font-size: 28px;
  }
  @keyframes ripple-white3 {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
        0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1);
    }
    100% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1),
        0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 100px rgba(255, 255, 255, 0);
    }
  }

  @media (min-width: 1200px) {
    .container-large {
      max-width: 1300px;
    }
  }

  @media all and (min-width: 1800px) {
    .container-large {
      max-width: 1700px;
    }
  }
}
